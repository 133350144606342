import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { uploadDocument } from '@/model/document'
import { useAuth } from '@/providers/AuthProvider'
import { useRef, useState } from 'react'

interface IPropTypes {
  workspaceId: string
}
export function UploadNewDocument({ workspaceId }: IPropTypes) {
  const { getIdToken } = useAuth()
  const [creating, setCreating] = useState(false)
  const [open, setOpen] = useState(false)

  const formData = useRef<{
    name: string
    file: File | null
  }>({
    name: 'Invoice ' + new Date().toLocaleDateString(),
    file: null
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setCreating(true)
    uploadDocument(
      formData.current.file as File,
      {
        workspaceId,
        name: formData.current.name
      },
      {
        Authorization: `Bearer ${await getIdToken()}`
      }
    ).then(() => {
      setCreating(false)
      setOpen(false)
    })
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button variant='default' size={'xs'}>
          Upload Document
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Upload New Document</DialogTitle>
            <DialogDescription>
              Upload a new document to this workspace for processing and
              extraction.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-4'>
              <Label htmlFor='name'>Document Name</Label>
              <Input
                id='name'
                className='col-span-3'
                defaultValue={formData.current.name}
                onChange={e => {
                  formData.current.name = e.target.value
                }}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <Label htmlFor='file'>Document</Label>
              <Input
                id='file'
                className='col-span-3'
                accept='image/*'
                type='file'
                onChange={e => {
                  formData.current.file = e.target.files?.[0] || null
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <Button type='submit' disabled={creating}>
              {creating ? 'Creating...' : 'Create'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

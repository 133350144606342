// AuthProvider.js
import { auth } from '@/config/Firebase'
import {
  GoogleAuthProvider,
  User,
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'

export const AuthContext = createContext({})

export const useAuth = () =>
  useContext(AuthContext) as {
    user: User | null
    loginUser: () => Promise<void>
    logOut: () => Promise<void>
    loading: boolean
    getIdToken: () => Promise<string>
  }

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  const getIdToken = async () => {
    if (!user) {
      return ''
    }
    return user.getIdToken()
  }
  const loginUser = () => {
    const google = new GoogleAuthProvider()
    setLoading(true)
    return signInWithPopup(auth, google)
  }

  const logOut = () => {
    setLoading(true)
    return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser)
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const authValue = {
    user,
    loginUser,
    logOut,
    loading,
    getIdToken
  }

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthProvider

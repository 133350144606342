import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { addNewColumnToWorkspace } from '@/model/workspace'
import { useRef, useState } from 'react'
import { z } from 'zod'

const createColumnSchema = z.object({
  name: z.string().nonempty(),
  type: z.string().nonempty().default('string')
})

interface IPropTypes {
  workspaceId: string
}

export function AddNewColumn({ workspaceId }: IPropTypes) {
  const [creating, setCreating] = useState(false)
  const [open, setOpen] = useState(false)

  const formData = useRef({
    name: '',
    description: ''
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const parsedData = createColumnSchema.safeParse({
      name: formData.current.name
    })

    if (!parsedData.success) {
      console.error(parsedData.error.errors)
      return
    }
    if (parsedData.data.name === "documentName" || parsedData.data.name === "link") {
      alert("Column name cannot be 'documentName' or 'link'")
      return
    }

    setCreating(true)
    return addNewColumnToWorkspace(workspaceId, parsedData.data)
      .then(id => {
        setOpen(false)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setCreating(false)
      })
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button variant='secondary' size={'xs'}>
          Create new column
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create Column</DialogTitle>
            <DialogDescription>
              Create a new column for the workspace so that the data in each
              document can be extracted and organized.
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-4'>
              <Label htmlFor='name'>Column Name</Label>
              <Input
                id='name'
                className='col-span-3'
                onChange={e => {
                  formData.current.name = e.target.value
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <Button type='submit' disabled={creating}>
              {creating ? 'Creating...' : 'Create'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

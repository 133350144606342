// PrivateRoute.js
import { useAuth } from '@/providers/AuthProvider'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { loading, user } = useAuth()

  if (loading) {
    return <span className='loading loading-dots loading-lg'></span>
  }

  if (user) {
    return children
  }

  return <Navigate to='/login' />
}

PrivateRoute.propTypes = {
  children: PropTypes.node
}

export default PrivateRoute

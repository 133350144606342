import { useCallback, useEffect, useMemo, useState } from 'react'

import { DataTable } from '@/components/ui/data-table'
import { listenToDoctuments } from '@/model/workspace'
import { IWorkspace } from '@/schema/workspaceSchemas'
import { Link } from 'react-router-dom'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'
// Row Data Interface
interface IRow {
  id: string
  link: string
  [key: string]: any
}

interface IProps {
  workspace: IWorkspace
}
// Create new GridExample component
const DocumentList = ({ workspace }: IProps) => {
  const [documents, setDocuments] = useState<IRow[]>([])

  const subscribe = useCallback(() => {
    listenToDoctuments(workspace.id, setDocuments)
  }, [workspace, setDocuments])

  useEffect(() => {
    subscribe()
  }, [subscribe])

  const documentColumns = useMemo(() => {
    return (
      workspace?.columns?.map(col => {
        return {
          accessorKey: col.name,
          headerName: col.name,
          type: col.type as any
        }
      }) || []
    )
  }, [workspace])

  return (
    <div
      className={'ag-theme-quartz'}
      style={{
        width: '100%',
        height: 'calc(100vh - 100px)'
      }}
    >
      <DataTable
        data={documents}
        columns={[
          {
            accessorKey: 'documentName',
            cell: ({ row }) => {
              return (
                <HoverCard>
                  <HoverCardTrigger>
                    <Link to={row.original.link} className='underline' target='_blank'>
                      {row.original.documentName}
                    </Link>
                  </HoverCardTrigger>
                  <HoverCardContent className='!bg-show'>
                    <img
                      src={row.original.link}
                      alt={row.original.documentName}
                      className='max-h-full min-h-60'
                    />
                  </HoverCardContent>
                </HoverCard>
              )
            }
          },
          ...documentColumns
        ]}
      />
    </div>
  )
}

export default DocumentList

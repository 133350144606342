'use client'
import IconSidebar from '@/components/shared/icons/sidebar'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb'
import { IWorkspace } from '@/schema/workspaceSchemas'
import { Question } from '@phosphor-icons/react'
import { AddNewColumn } from './AddNewColumn'

interface IWorkspaceHeaderProps {
  actions: React.ReactNode
  workspace?: IWorkspace
}

export default function WorkspaceHeader({
  actions,
  workspace
}: IWorkspaceHeaderProps) {
  return (
    <div className='bg-gray1'>
      <div className='flex items-center justify-between gap-2 border-b-[1px] py-2'>
        <div className='flex items-center gap-2 px-2'>
          <div className='group/svg group hover:cursor-pointer'>
            <IconSidebar className='size-[12px]' />
          </div>
          <div className='ml-1.5 h-5 w-[2px] rounded border border-gray6' />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href='/'>Your Workspaces</BreadcrumbLink>
              </BreadcrumbItem>
              {workspace && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbPage>{workspace.name}</BreadcrumbPage>
                  </BreadcrumbItem>
                </>
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className='flex items-center gap-2 px-2'>
          {actions}
          <div className='rounded-full bg-gray2 p-1'>
            <Question />
          </div>
        </div>
      </div>
      {workspace && (
        <div className='flex h-[42px] items-center justify-between px-2'>
          <div className='flex items-center justify-between gap-3'>
            <div className='flex items-center'>
              <div className='text-xs font-semibold text-gray11'>File Data</div>
              <div className='ml-1.5 h-5 w-[2px] rounded border border-gray6' />
            </div>
          </div>
          <AddNewColumn workspaceId={workspace.id} />
        </div>
      )}
    </div>
  )
}

import { db } from '@/config/Firebase'
import { workspaceListSchema } from '@/schema/workspaceSchemas'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where
} from 'firebase/firestore'

const workspaceCollectionName = 'workspaces'
const workspaceCollection = collection(db, workspaceCollectionName)

export const createWorkspace = (data: {
  name: string
  description: string
}) => {
  const newDocRef = addDoc(workspaceCollection, data)
  return newDocRef.then(docRef => docRef.id)
}

export const addNewColumnToWorkspace = (
  workspaceId: string,
  newColumn: {
    name: string
    type: string
  }
) => {
  // fetch the workspace from the database
  // add the column to the workspace
  // update the workspace in the database
  const workspaceRef = doc(db, workspaceCollectionName, workspaceId)
  const workspaceData = getDoc(workspaceRef).then(doc => doc.data())
  return workspaceData.then(data => {
    if (!data) {
      throw new Error('Workspace not found')
    }
    const updatedData = {
      ...data,
      columns: [...(data.columns || []), newColumn]
    }
    return setDoc(workspaceRef, updatedData).then(() => updatedData)
  })
}

export const listenToWorkspace = (
  workspaceId: string,
  callback: (data: any) => void
) => {
  const workspaceRef = doc(db, workspaceCollectionName, workspaceId)
  onSnapshot(workspaceRef, doc => {
    callback({
      id: doc.id,
      ...doc.data()
    })
  })
}

export const listWorkspaces = (userId: string) => {
  const querySnapshot = getDocs(
    query(workspaceCollection, where('userId', '==', userId))
  )
  return querySnapshot
    .then(snapshot =>
      snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    )
    .then(data => {
      const parsedData = workspaceListSchema.safeParse(data)
      if (!parsedData.success) {
        throw new Error(parsedData.error.errors.join(', '))
      }
      return parsedData.data
    })
}

export const listenToDoctuments = (
  workspaceId: string,
  callback: (data: any) => void
) => {
  const workspaceRef = doc(db, workspaceCollectionName, workspaceId)
  const documentsRef = collection(workspaceRef, 'documents')
  onSnapshot(documentsRef, snapshot => {
    callback(
      snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    )
  })
}

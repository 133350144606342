import { UPLOAD_DOCUMENT_PATH } from '@/config/routes'

export const uploadDocument = (
  file: File,
  data: {
    workspaceId: string
    name: string
  },
  headers: {
    [key: string]: string
  }
) => {
  const formData = new FormData()
  formData.append('document', file)

  Object.keys(data).forEach(key => {
    formData.append(key, data[key as keyof typeof data])
  })

  return fetch(UPLOAD_DOCUMENT_PATH, {
    method: 'POST',
    body: formData,
    headers
  })
}

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { createWorkspace } from '@/model/workspace'
import { useAuth } from '@/providers/AuthProvider'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { Textarea } from '../ui/textarea'

const createWorkspaceSchema = z.object({
  name: z.string().nonempty(),
  description: z.string().nonempty(),
  userId: z.string().nonempty()
})

export function CreateNewWorkspace() {
  const { user } = useAuth()
  const [creating, setCreating] = useState(false)
  const navigate = useNavigate()

  const formData = useRef({
    name: '',
    description: ''
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!user) {
      return
    }
    const parsedData = createWorkspaceSchema.safeParse({
      name: formData.current.name,
      description: formData.current.description,
      userId: user.uid
    })
    if (!parsedData.success) {
      console.error(parsedData.error.errors)
      return
    }
    console.log(parsedData.data)
    setCreating(true)
    return createWorkspace(parsedData.data)
      .then(id => {
        console.log('Workspace created with id:', id)
        return navigate(`/workspace/${id}`)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setCreating(false)
      })
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='default' size={'xs'}>
          Create new workspace
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create new workspace</DialogTitle>
            <DialogDescription>
              Workspace is the place where you can manage all the document of
              same structure
            </DialogDescription>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <div className='flex flex-col gap-4'>
              <Label htmlFor='name'>Name</Label>
              <Input
                id='name'
                className='col-span-3'
                onChange={e => {
                  formData.current.name = e.target.value
                }}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <Label htmlFor='description'>Description</Label>
              <Textarea
                id='description'
                className='col-span-3'
                onChange={e => {
                  formData.current.description = e.target.value
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <Button type='submit' disabled={creating}>
              {creating ? 'Creating...' : 'Create'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

import HomePage from '@/components/screens/HomePage'
import LoginPage from '@/components/screens/LoginPage'
import WorkspaceSinglePage from '@/components/screens/WorkspaceSinglePage'
import PrivateRoute from '@/components/shared/auth/PrivateRoute'
import { createBrowserRouter } from 'react-router-dom'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <HomePage />
      </PrivateRoute>
    )
  },
  {
    path: '/workspace/:workspaceId',
    element: (
      <PrivateRoute>
        <WorkspaceSinglePage />
      </PrivateRoute>
    )
  },
  {
    path: '/login',
    element: <LoginPage />
  }
])

import { Badge } from '@/components/ui/badge'
import { listWorkspaces } from '@/model/workspace'
import { useAuth } from '@/providers/AuthProvider'
import { IWorkspaceList } from '@/schema/workspaceSchemas'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CreateNewWorkspace } from './CreateNewWorkspace'
import WorkspaceHeader from './WorkspaceHeader'

export default function WorkspaceList() {
  const { user } = useAuth()
  const [loading, setLoading] = useState(true)
  // formatting
  const [workspaces, setWorkspaces] = useState<IWorkspaceList>([])

  const fetchData = useCallback(async () => {
    if (!user) {
      return setLoading(false)
    }
    return listWorkspaces(user?.uid)
      .then(data => {
        setWorkspaces(data)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <WorkspaceHeader actions={<CreateNewWorkspace />} />
      {loading ? (
        <div className='flex h-screen'>
          <div className='m-auto'>
            <h3 className='text-sm text-gray11'>Loading your workspaces...</h3>
          </div>
        </div>
      ) : (
        <div className='p-2 md:py-10'>
          <div className='flex flex-col gap-5'>
            <ul className='grid grid-cols-1 gap-4 md:grid-cols-3'>
              {workspaces.map(workspace => (
                <li
                  key={workspace.id}
                  className='rounded-lg border border-gray5 p-4 hover:bg-gray5'
                >
                  <Link to={`/workspace/${workspace.id}`}>
                    <div className='flex justify-between'>
                      <h3 className='text-base font-medium'>{workspace.name}</h3>
                      <Badge variant={'outline'} className='rounded-full'>
                        <span className='text-regular font-base'>3 Documents</span>
                      </Badge>
                    </div>
                    <p className='text-sm text-gray11'>
                      {workspace.description}
                    </p>
                   
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

import DocumentList from '@/components/workspace/DocumentList'
import { UploadNewDocument } from '@/components/workspace/UploadNewDocument'
import WorkspaceHeader from '@/components/workspace/WorkspaceHeader'
import { listenToWorkspace } from '@/model/workspace'
import { IWorkspace } from '@/schema/workspaceSchemas'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function WorkspaceSinglePage() {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const [workspace, setWorkspace] = useState<IWorkspace | undefined>()

  const subscribe = useCallback(() => {
    if (!workspaceId) {
      return ''
    }

    listenToWorkspace(workspaceId, data => {
      setWorkspace(data)
    })
  }, [workspaceId])

  useEffect(() => {
    subscribe()
  }, [subscribe])

  return (
    <div className='flex min-h-full flex-col'>
      <WorkspaceHeader
        workspace={workspace}
        actions={
          workspace ? (
            <div className='flex items-center justify-end gap-2 px-2'>
              <UploadNewDocument workspaceId={workspace.id} />
            </div>
          ) : undefined
        }
      />
      {!workspace ? (
        <div className='flex h-screen'>
          <div className='m-auto'>
            <h3 className='text-sm text-gray11'>Loading workspaces...</h3>
          </div>
        </div>
      ) : (
        <DocumentList workspace={workspace} />
      )}
    </div>
  )
}

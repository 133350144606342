import { auth } from '@/config/Firebase'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Button } from '@/components/ui/button'
import { useNavigate } from 'react-router-dom'

export default function LoginPage() {
  const navigate = useNavigate()

  const handleLogin = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider).then(result => {
      // IF sucess navigate to dashboard
      if (result) {
        navigate('/')
      }
    })
  }

  return (
    <div>
      <Button onClick={handleLogin}>Login With Google</Button>
    </div>
  )
}

import { z } from 'zod'

export const workspaceSchema = z.object({
  id: z.string().nonempty(),
  name: z.string().nonempty(),
  description: z.string().nonempty(),
  userId: z.string().nonempty(),
  columns: z
    .array(
      z.object({
        name: z.string().nonempty(),
        type: z.string().nonempty()
      })
    )
    .default([])
})

export interface IWorkspace extends z.infer<typeof workspaceSchema> {}

export const workspaceListSchema = z.array(workspaceSchema)

export interface IWorkspaceList extends z.infer<typeof workspaceListSchema> {}
